/* General Styles */
:root {
  --primary-color: #007bff;
  --secondary-color: #0056b3;
  --background-color: #f7f7f7;
  --text-color: #333;
  --border-color: #ddd;
}

body {
  font-family: "Helvetica Neue", Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.7;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

/* Responsive typography */
@media (max-width: 768px) {
  body {
    font-size: 14px;
  }
}

/* Flight Info Section */
.flight-info {
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.flight-info:hover {
  transform: scale(1.02);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

/* Table Styles */
table {
  border-collapse: collapse;
  width: 100%;
  background-color: #fff;
  margin-bottom: 2rem;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

th,
td {
  text-align: left;
  padding: 1rem;
  border-bottom: 1px solid var(--border-color);
  font-size: 1rem;
}

th {
  background-color: #f4f4f4;
  font-weight: bold;
  color: var(--text-color);
  text-transform: uppercase;
}

td {
  font-size: 0.95rem;
}

tr:hover {
  background-color: #f9f9f9;
  transition: background-color 0.3s ease;
}

/* Responsive layout */
.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.column {
  flex: 1 1 300px;
  margin: 1rem;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .flight-info {
    padding: 1.5rem;
  }

  table {
    font-size: 0.9rem;
  }

  th,
  td {
    padding: 0.75rem;
  }

  .column {
    flex: 1 1 100%;
  }
}

@media (max-width: 480px) {
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }

  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    margin-bottom: 1rem;
    border: 1px solid var(--border-color);
  }

  td {
    border: none;
    position: relative;
    padding-left: 50%;
  }

  td:before {
    content: attr(data-label);
    position: absolute;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    font-weight: bold;
  }
}

/* Link and Button Styles */
a {
  color: var(--primary-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  text-decoration: underline;
  color: var(--secondary-color);
}

button {
  background-color: var(--primary-color);
  color: #fff;
  padding: 0.625rem 1.25rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1rem;
}

button:hover {
  background-color: var(--secondary-color);
}

button:active {
  background-color: #004494;
}

/* Form Styles */
input,
select,
textarea {
  font-family: inherit;
  font-size: 1rem;
  padding: 0.75rem;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  width: 100%;
  margin-bottom: 1rem;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

input:focus,
select:focus,
textarea:focus {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: 0px 0px 8px rgba(0, 123, 255, 0.25);
}

/* Accessibility Enhancements */
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

/* High contrast mode */
@media (prefers-color-scheme: dark) {
  :root {
    --background-color: #121212;
    --text-color: #ffffff;
    --border-color: #333333;
  }

  .flight-info,
  table {
    background-color: #1e1e1e;
  }

  th {
    background-color: #2c2c2c;
  }

  tr:hover {
    background-color: #2a2a2a;
  }
}
